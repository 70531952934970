$breakpoints: (
  xs: '500px',
  xsPlus: '660px',
  s: '769px',
  smed: '940px',
  m: '1025px',
  l: '1281px',
  xl: '1441px'
);

$blue: #00afd7;
$blue2: #44c6e3;
$green: #82bc00;
$green2: #97ca5e;
$green600: #82BC00;
$green600-a05: #82BC000D;
$aqua: #00c9bc;
$aqua2: #4bdbd2;
$denim: #628be3;
$denim2: #799ff2;
$teal600: #00AFD7;
$teal600-a05: #00AFD70D;
$teal700: #007FA5;
$teal700-hover: #007FA50D;
$teal700-focus: #007FA559;
$teal800: #006B8B;
$teal-A05: #0051690D;
$grapefruit: #ed6e47;
$grapefruit2: #ff8863;
$orange: #ff8200;
$medium-orange: #f89e3d;
$yellow: #fcba44;
$gold400: #FFC765;
$gold500: #FCBA44;
$gold600: #f89e3d;
$gold600-a05: #f89e3d0D;
$gold700: #ED973B;
$red: #f04848;
$cranberry: #f05670;
$cranberry2: #ff7088;
$cranberry600: #F05670;
$cranberry600-a05: #F056700D;
$pink: #e67acb;
$pink2: #fa93dd;
$purple: #9c6dc7;
$purple2: #b389db;
$slate: #59738f;
$slate2: #768ca3;
$light-gray: #f7f7f7;
$gray94: #f0f0f0;
$gray92: #ebebeb;
$gray87: #ddd;
$gray89: #dddddd;
$gray100: #F8F8F9;
$gray200: #E9EBED;
$gray300: #D8DCDE;
$gray400: #C2C8CC;
$gray500: #87929D;
$gray500-hover: #87929D0D;
$gray500-pressed: #87929D26;
$gray600: #68737D;
$gray600-a05: #68737D0D;
$gray700: #49545C;
$gray800: #2F3941;
$white: #FFF;
$black: #000;
$medium-gray: #c5cdd1;
$gray: #939da3;
$dark-gray: #3d444c;
$shadow-medium: #00000024;
$header-height: 48px;
$left-nav-width: 240px;
$base-link-color: #007bbd;
$hover-link-color: darken($base-link-color, 15%);
$base-box-shadow: 0 1px 3px $shadow-medium;
$base-border-radius: 4px;
$celeritas-border-radius: 8px;
$base-font-color: #333;
$base-font-family: 'Roboto', Arial, sans-serif;
$base-font-size: 14px;
$tiny-font-size: 12px;
$base-header-font-size: 16px;
$header-font-family: $base-font-family;
$sub-menu-line: #494f56;
$sub-menu-background: #353c43;
$contentPadding: 64px;
$brown-red: #B14300;
$brown-red-a05: #B143000D;

$usersTable-pin-width: 32px;
$header-height: 48px;
$nav-actions-height: 176px;
$nav-padding: 32px;
$nav-filter-transitionTime: 400ms;
$nav-filterSelected-background: $gold500;
$nav-borderWidth: 3px;
$nav-tab-height: 48px;
$dropdown-margin: 0 0 8px 32px;
$quickPoll-label-width: 80px;
$input-height: 36px;
$time-input-width-firefox: 100px;

// Tooltips
$tooltip-arrow-size: 6px;

$c-grey50: #fafafa;
$c-grey100: #f8f8f9;
$c-grey200: #e9ebed;
$c-grey300: #d8dcde;
$c-grey400: #c2c8cc;
$c-grey500: #87929d;
$c-grey600: #68737d;
$c-grey700: #49545c;
$c-grey800: #2f3941;
$c-primary: #007fa5;

$c-background-body: white;
$c-primary: #007fa5;
$c-primary-1a: #007fa51a;
$c-primary-26: #007fa526;
$c-primary-40: #007fa540;
$c-primary-59: #007fa559;
$c-primary-0d: #007fa50d;
$c-primary-active: #005169;
$c-primary-active-bg: #007fa526;
$c-primary-hover: #006b8b;
$c-indigo: #041e42;
$c-error: #a21355;
$c-error-59: #a2135559;
$c-error-1a: #a213551a;
$c-error-bg: #a213550d;
$c-danger: #a21355;
$c-danger-59: #a2135559;
$c-danger-0d: #a213550d;
$c-danger-1a: #a213551a;
$c-warning: #b14300;
$c-warning-0d: #b143000d;
$c-warning-1a: #b143001a;
$c-warning-59: #b1430059;
$c-success: #15655c;
$c-success-1a: #15655c1a;
$c-success-26: #15655C26;
$c-blue400: #48d1f0;
$c-blue500: #44c6e3;
$c-blue600: #00afd7;
$c-blue700: #007fa5;
$c-green400: #9fd463;
$c-green500: #97ca5e;
$c-green600: #82bc00;
$c-green700: #78b000;
$c-gold400: #ffc765;
$c-gold500: #fcba44;
$c-gold600: #f89e3d;
$c-gold700: #ed973b;
$c-cranberry400: #ff7088;
$c-cranberry500: #fd617c;
$c-cranberry600: #f05670;
$c-cranberry700: #e3526a;
$c-purple400: #bc91e6;
$c-purple500: #b389db;
$c-purple600: #9c6dc7;
$c-purple700: #9266ba;
$c-pink400: #ff9fe3;
$c-pink500: #fa93dd;
$c-pink600: #e67acb;
$c-pink700: #db74c2;
$c-denim400: #7ea6fc;
$c-denim500: #799ff2;
$c-denim600: #628be3;
$c-denim700: #5c83d6;
$c-grapefruit400: #ff9676;
$c-grapefruit500: #ff8863;
$c-grapefruit600: #ed6e47;
$c-grapefruit700: #e36944;
$c-aqua400: #4de3d9;
$c-aqua500: #4bdbd2;
$c-aqua600: #00c9bc;
$c-aqua700: #00bfb3;
$c-slate400: #7f96b0;
$c-slate500: #768ca3;
$c-slate600: #59738f;
$c-slate700: #526b85;
$c-grey50: #fafafa;
$c-grey100: #f8f8f9;
$c-grey200: #e9ebed;
$c-grey300: #d8dcde;
$c-grey400: #c2c8cc;
$c-grey500: #87929d;
$c-grey600: #68737d;
$c-grey700: #49545c;
$c-grey800: #2f3941;
