@import '../../../css/base/_variables.scss';

@mixin tooltip-mixin($direction, $transform-translate) {
  transform-origin: $direction;
  transition: all 0.18s ease-out 0.1s;
  transform: translate3d($transform-translate);
}

@mixin tooltip-hover-mixin($transform-translate) {
  transform: translate3d($transform-translate);
  transition-delay: 0.25s;
}

.tooltipWrapper {
  position: relative;
}

// TOOLTIPS
.tooltip {
  font-family: $base-font-family;
  letter-spacing: 0.0035px;
  position: absolute;
  border-radius: 8px;
  left: 50%;
  transform: translateX(-50%);
  padding: 8px;
  color: $white;
  background: $gray800;
  font-size: 14px;
  line-height: 20px;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
  transition: all 0.18s ease-out 0.1s;

  &.small {
    white-space: nowrap;
    height: 36px;
  }

  &.medium {
    overflow-wrap: wrap;
    width: 176px;
    height: 60px;
    white-space: normal;
    display: table;
  }

  &.large {
    overflow-wrap: wrap;
    width: 233px;
  }

  // custom class for checkedIn tooltip in user table and heat map
  // set as size prop
  &.notCheckedIn {
    width: 187px;
    white-space: normal;
  }

  // custom class for tooltips that need text to be centered
  // set as customClassTwo prop (applies to span
  .centerText {
    text-align: center;
    display:table-cell;
    vertical-align: middle;
  }

  // Border triangles
  &::before {
    content: " ";
    left: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: $tooltip-arrow-size;
    margin-left: calc(#{$tooltip-arrow-size} * -1);
  }
}

// Hover behavior applied to tooltip on parent hover
.tooltipHoverContainer:hover > .tooltip {
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0.25s;
}

// TOP TOOLTIPS
.top {
  @include tooltip-mixin(top, (-50%, 16px, 0));
  top: auto;
  bottom: 135%;

  &.medium {
    top: auto;
    bottom: 120%;
  }

  &.large {
    top: auto;
    bottom: 150%;
  }

  // custom class for checkedIn tooltip in user table and heat map
  // set as size prop 
  &.notCheckedIn {
    top: auto;
    bottom: 150%;

    &.heatMapNotCheckedIn {
      top: auto;
      bottom: calc(100% + 8px);
    }
  }
  
  // CSS border triangles
  &::before {
    top: 100%;
    border-top-color: $gray800;
  }
}

// Hover behavior applied to tooltip on parent hover
.tooltipHoverContainer:hover > .top {
  @include tooltip-hover-mixin((-50%, 0, 0));
}

// BOTTOM TOOLTIPS
.bottom {
  @include tooltip-mixin(top, (-50%, 16px, 0));
  bottom: auto;
  top: 135%;
  
  &.large {
    bottom: auto;
    top: 140%;
  }
  
  // CSS border triangles
  &::before {
    bottom: 100%;
    border-bottom-color: $gray800;
  }
}

// Hover behavior applied to tooltip on parent hover
.tooltipHoverContainer:hover > .bottom {
  @include tooltip-hover-mixin((-50%, 0, 0));
}

// RIGHT TOOLTIP
.right {
  @include tooltip-mixin(right, (16px, -50%, 0));
  left: calc(100% + 8px);
  top: 50%;

  // CSS border triangles
  &::before {
    left: calc(#{$tooltip-arrow-size} * -1);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: $gray800;
  }
}

// Hover behavior applied to tooltip on parent hover
.tooltipHoverContainer:hover > .right {
  @include tooltip-hover-mixin((0, -50%, 0));
}

// LEFT TOOLTIP
.left {
  @include tooltip-mixin(right, (16px, -50%, 0));
  left: auto;
  right: calc(100% + 8px);
  top: 50%;

  // CSS border triangles
  &::before {
    left: auto;
    right: calc(#{$tooltip-arrow-size} * -2);
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-left-color: $gray800;
  }
}

// Hover behavior applied to tooltip on parent hover
.tooltipHoverContainer:hover > .left {
  @include tooltip-hover-mixin((0, -50%, 0));
}
